import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
    Main,
    Portal,
    GameIndex,
    ClipViewer,
    ProjectsViewer,
} from './components';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route exact path="/connect" element={<Portal />} />
                <Route exact path="/projects" element={<ProjectsViewer />} />
                {/* projects  */}
                <Route exact path="/alien-game" element={<GameIndex />} />
                <Route exact path="/clip-viewer" element={<ClipViewer />} />
                {/* not found go main  */}
                <Route path="*" element={<Main />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
