import { Suspense, useEffect, useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import {
    useVideoTexture,
    Html,
    OrbitControls,
    useGLTF,
    useAspect,
    Text,
} from '@react-three/drei';
import * as THREE from 'three';

const Background = () => {
    const texture = useLoader(
        THREE.TextureLoader,
        '/clip-viewer-assets/bg.jpg'
    ); // Replace with your image path

    return (
        <mesh scale={100}>
            <sphereGeometry args={[1, 32, 32]} />
            <meshBasicMaterial map={texture} side={THREE.BackSide} />
        </mesh>
    );
};

function Scene() {
    // const size = useAspect(1800, 1000);
    return (
        <mesh scale={[16, 9, 1]}>
            <planeGeometry />
            <VideoMaterial url="/clip-viewer-assets/halo.mp4" />
        </mesh>
    );
}

function Ground(props) {
    const { nodes, materials } = useGLTF('/floor.glb');
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane.geometry}
                material={materials.Material}
            />
        </group>
    );
}

function VideoMaterial({ url }) {
    const texture = useVideoTexture(url);

    const [isMuted, setIsMuted] = useState(true);

    useEffect(() => {
        if (texture?.image) {
            const video = texture.image;

            // Unmute the video and play it when the user interacts (e.g., clicks the screen)
            const unmuteOnButtonClick = () => {
                if (isMuted) {
                    video.muted = false;
                    video.volume = 1.0;
                    setIsMuted(false);
                } else {
                    video.muted = true;
                    setIsMuted(true);
                }
            };

            // Listen for the custom event
            window.addEventListener('unmute-video', unmuteOnButtonClick);

            return () => {
                window.removeEventListener('unmute-video', unmuteOnButtonClick);
            };
        }
    }, [texture, isMuted]);

    return (
        <group>
            {/* Video plane - slightly smaller to fit inside the border */}
            <mesh scale={[0.95, 0.95, 0.95]} position={[0, 0, 0.0015]}>
                <planeGeometry />
                <meshBasicMaterial
                    map={texture}
                    toneMapped={false}
                    depthWrite={true} // Ensure depth is written correctly
                    depthTest={true} // Ensure depth testing works
                />
            </mesh>

            {/* Border - slightly larger plane */}
            <mesh scale={[1, 1, 10]}>
                <planeGeometry />
                <meshBasicMaterial color="white" side={THREE.DoubleSide} />
            </mesh>
        </group>
    );
}

function MuteButton({ position }) {
    const [isMuted, setIsMuted] = useState(true);

    const handleMuteClick = () => {
        // Dispatch a custom event to unmute the video
        const event = new CustomEvent('unmute-video');
        window.dispatchEvent(event);
        setIsMuted(!isMuted);
    };

    return (
        <group position={position} onClick={handleMuteClick}>
            {/* Background Plane */}
            <mesh position={[0, 0, 0]}>
                <boxGeometry args={[1, 1, 0.2]} />
                <meshBasicMaterial color="lightblue" />
            </mesh>

            {/* 3D Text */}
            <Text
                fontSize={0.5}
                color="black"
                anchorX="center"
                anchorY="middle"
                position={[0, 0, 0.15]} // Ensure it's on top of the plane
            >
                {isMuted ? '🔇' : '🔊'}
            </Text>
        </group>
    );
}

export default function ClipViewer() {
    return (
        <div style={{ height: '100%', width: '100%', background: '#246aa1' }}>
            <Canvas camera={{ position: [-15, -2, 30], fov: 120 }}>
                <Suspense
                    fallback={
                        <Html center>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh', // Full viewport height
                                    width: '100vw', // Full viewport width
                                    color: 'black',
                                    fontSize: '1.5rem',
                                }}
                            >
                                loading...
                            </div>
                        </Html>
                    }
                >
                    <Scene />
                    <Ground scale={14} position={[0, -6, 0]} />
                    <Background />
                    <OrbitControls
                        maxDistance={30} // Max zoom out distance
                        minDistance={5} // Min zoom in distance
                    />
                    <MuteButton position={[8, -3, 1]} />
                    <ambientLight intensity={2} />
                </Suspense>
            </Canvas>
        </div>
    );
}
