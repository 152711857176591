import { NavLink } from 'react-router-dom';

import './ProjectsViewer.scss';

const ProjectsViewer = () => {
    return (
        <div className="project-component">
            <div className="content">
                <div className="header">
                    <div>Projects and such</div>
                    <div className="sub-header">
                        some things may be finished, some may be in progress.
                        it's not that serious.
                    </div>
                </div>
                <div className="projects-list">
                    {/* clip viewer  */}
                    <NavLink to="/clip-viewer" className="project">
                        <div className="project-title">Clip Viewer</div>
                        <div className="project-desc">
                            React three fiber video player
                        </div>
                    </NavLink>

                    {/* game  */}
                    <NavLink to="/alien-game" className="project">
                        <div className="project-title">Alien Game</div>
                        <div className="project-desc">
                            React three fiber fps game
                        </div>
                    </NavLink>
                </div>
                <div className="list-spacer"></div>
            </div>
        </div>
    );
};

export default ProjectsViewer;
