import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import './Portal.scss';

const Portal = () => {
    return (
        <div className="portal-container">
            <div className="content">
                <div className="header-container">
                    Hi, I'm <h1 className="header">Tyypos</h1>
                </div>
                <div className="about-container">
                    <div>I write code.</div>
                    <div>I run trails.</div>
                    <div>I game.</div>
                </div>
                <div className="divider"></div>
                <div className="icon-container">
                    <div className="project-container">
                        <NavLink to="/projects" className="project-link">
                            PROJECTS
                        </NavLink>
                    </div>
                    <div>
                        <a
                            href="https://twitter.com/tyypos_"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faTwitter}
                                className="icon"
                            />
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://www.instagram.com/tyypos"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="icon"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portal;
