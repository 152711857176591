import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Html, PointerLockControls } from '@react-three/drei';
import { Ground } from './Ground/Ground';
import { FirstPersonCameraControls } from './FirstPersonCameraControls/FirstPersonCameraControls';
import Background from './Background/Background';
import FPSDisplay from './FPSDisplay/FPSDisplay';
import Wall from './Wall/Wall';
import Gun from './Gun/Gun';
import Enemy from './Enemy/Enemy';

// todo: when actually passing array it breaks....
const enemiesData = [
    // { position: [0, 0, -80], speed: 0.02 },
    // { position: [0, 0, -80], speed: 0.1 },
    { position: [0, 0, -80], speed: 0.1 },
    // Add more enemies with different positions and speeds
];

export default function GameIndex() {
    return (
        <div style={{ height: '100%', width: '100%', background: '#060C0C' }}>
            <Canvas>
                <Suspense
                    fallback={
                        <Html center>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh', // Full viewport height
                                    width: '100vw', // Full viewport width
                                    color: 'white',
                                    fontSize: '1.5rem',
                                }}
                            >
                                loading...
                            </div>
                        </Html>
                    }
                >
                    {/* enemies  */}
                    {enemiesData.map((enemy, index) => (
                        <Enemy
                            key={index}
                            position={enemy.position}
                            scale={5}
                            speed={enemy.speed}
                        />
                    ))}
                    <Gun scale={5} rotation={[Math.PI / 2, 0, 0]} />
                    {/* Camera setup component */}
                    <FirstPersonCameraControls />
                    {/* Pointer Lock Controls */}
                    <PointerLockControls />
                    {/* ground  */}
                    <Ground scale={40} />
                    {/* add front extentsion  */}
                    <Ground position={[0, 0, -80]} scale={40} />
                    {/* add back extention  */}
                    <Ground position={[0, 0, 80]} scale={40} />
                    {/* right extentions  */}
                    <Ground position={[80, 0, 0]} scale={40} />
                    <Ground position={[80, 0, 80]} scale={40} />
                    <Ground position={[80, 0, -80]} scale={40} />
                    {/* left extentions  */}
                    <Ground position={[-80, 0, 0]} scale={40} />
                    <Ground position={[-80, 0, 80]} scale={40} />
                    <Ground position={[-80, 0, -80]} scale={40} />
                    {/* Left wall */}
                    <Wall position={[-120, 5, 0]} scale={[0.01, 10, 239.99]} />
                    {/* front wall  */}
                    <Wall
                        position={[0, 5, -120]}
                        scale={[0.01, 10, 239.99]}
                        rotation={[0, Math.PI / 2, 0]}
                    />
                    {/* right wall  */}
                    <Wall position={[120, 5, 0]} scale={[0.01, 10, 239.99]} />
                    {/* back wall  */}
                    <Wall
                        position={[0, 5, 120]}
                        scale={[0.01, 10, 239.99]}
                        rotation={[0, Math.PI / 2, 0]}
                    />
                    {/* background  */}
                    <Background />
                    {/* Lighting */}
                    <ambientLight intensity={1.5} />
                    {/* stats  */}
                    <FPSDisplay />
                </Suspense>
            </Canvas>
        </div>
    );
}
