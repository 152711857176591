/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

export default function Enemy(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF('/alien.glb');
    const { actions } = useAnimations(animations, group);
    const { camera } = useThree();
    const groundY = 0; // Set this to the desired ground level
    const stoppingDistance = 7.5; // Set this to the distance at which the enemy should stop

    useFrame(() => {
        if (group.current) {
            const enemyPosition = group.current.position;
            const playerPosition = camera.position;

            // Calculate direction from enemy to player
            const direction = new THREE.Vector3()
                .subVectors(playerPosition, enemyPosition)
                .normalize();

            // Calculate distance to player
            const distanceToPlayer = enemyPosition.distanceTo(playerPosition);

            // Move enemy towards player if beyond stopping distance
            if (distanceToPlayer > stoppingDistance) {
                group.current.position.addScaledVector(direction, props.speed);
                actions.Defeated.stop();
            } else {
                actions.Defeated.play();
            }

            // Constrain the enemy to the ground
            group.current.position.y = groundY;

            // Check for collision with player (if within stopping distance)
            if (distanceToPlayer < stoppingDistance) {
                // Adjust the collision threshold as needed
                console.log('Enemy hit the player!');
                // Implement damage or any other interaction logic here
            }

            group.current.lookAt(
                camera.position.x,
                camera.position.y - 5,
                camera.position.z
            );
        }
    });

    useEffect(() => {
        // if (props.playAnimation) {}

        // actions.Idle2.play();
        actions.Walk.play();
        console.log('actions', actions);
    }, [props, actions]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="AuxScene">
                <group position={[-0.002, -0.014, 0.012]}>
                    <skinnedMesh
                        name="unamed"
                        geometry={nodes.unamed.geometry}
                        material={materials.mat0mat}
                        skeleton={nodes.unamed.skeleton}
                    />
                    <primitive object={nodes.mixamorigHips} />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload('/alien.glb');
